import React, { useState, useEffect, useRef } from "react";
import { API_RESPONSE } from "../../constants";
import { useSelector, useDispatch } from "react-redux";

function HotelListingCustomDropDown(props) {
  return (
    <div
      style={props.style?props.style:{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.5rem",
      }}
    >
      <div
        className="hl_custom_dropdown_cls"
        style={
          {
            // height: props.updatedFeild === props.dropDownFor ? "5.875rem" : null,
          }
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <p className="hl_feilds_lable_cls">{props.label}</p>
          </div>
        </div>

        <div
          ref={props.dropDownRef}
          className={`hotel_listing_custom_dropdown ${props.dropdownStatus ? "open" : ""}`}

          style={props.disabled ? { backgroundColor: "#f9f9f9", cursor: "not-allowed" } : {}}
        >
          <div
            onClick={() =>
              (props.dropDownFor === "state" ||
                props.dropDownFor === "city" ||
                props.dropDownFor === "area") &&
                props.dropdownStatus
                ? null
                : props.onHandleDropDown(props.dropDownFor)
            }
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              cursor: props.disabled ? "not-allowed" : "pointer",
            }}
          >
            <div>
              {(props.dropDownFor === "state" ||
                props.dropDownFor === "city" ||
                props.dropDownFor === "area") &&
                props.dropdownStatus ? (
                <div style={{ display: "flex" }}>
                  <svg
                    onClick={() => props.onHandleDropDown(props.dropDownFor)}
                    className="input_search_icon_svg"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="14.666"
                      cy="14.6666"
                      r="7.33333"
                      stroke="#9B97A0"
                      strokeWidth="1.33333"
                    />
                    <path
                      d="M20 20L25.6569 25.6569"
                      stroke="#9B97A0"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                    />
                  </svg>
                  <input
                    value={props.dropDownInputValue}
                    placeholder={
                      props.dropdownValue != null &&
                        props.dropdownValue.text !== undefined
                        ? props.dropdownValue.text
                        : props.placeholder
                    }
                    className="hl_dropdown_value_cls"
                    style={{
                      color: props.dropdownStatus ? "#1c1c1c" : "#818181",
                      width: "95%",
                      outline: "none",
                      borderWidth: 0,
                      backgroundColor: "transparent",
                    }}
                    onChange={(e) => props.onChangeInput(props.dropDownFor, e)}
                  />
                </div>
              ) : (
                <p
                  className="hl_dropdown_value_cls"
                  style={{
                    color:
                      props.dropdownStatus ||
                        props.dropdownValue === null ||
                        props.dropdownValue == undefined ||
                        props.dropdownValue.text === undefined
                        ? "#818181"
                        : "#1c1c1c",
                    paddingLeft: props.dropdownStatus ? "0.625rem" : 0,
                  }}
                >
                  {props.dropdownValue != null &&
                    props.dropdownValue.text !== undefined
                    ? props.dropdownValue.text.length >= 20
                      ? props.dropdownValue.text.slice(0, 20)+"..."
                      : props.dropdownValue.text
                    : props.placeholder}
                </p>
              )}
            </div>

            <div
              onClick={() => props.onHandleDropDown(props.dropDownFor)}
              className={`filtersDropDownIcon ${props.dropdownStatus ? "open" : ""
                }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="hl_dropdown_arrow_svg"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M6.71285 9.99541C7.11323 10.5064 7.88677 10.5064 8.28715 9.99541L14.4604 2.11677C14.9747 1.46043 14.5071 0.5 13.6733 0.5H1.32672C0.49291 0.5 0.025305 1.46044 0.53957 2.11677L6.71285 9.99541Z"
                  fill="#303030"
                />
              </svg>
            </div>
          </div>

          {props.dropdownStatus ? (
            props.dropDownFor === "state" ||
              props.dropDownFor === "city" ||
              props.dropDownFor === "area" ? (
              <div className="hl_dropdown_options_cls">
                {props.dropdownOptions.length !== 0 ? (
                  props.dropdownOptions.map((option) => {
                    return (
                      option.text
                        .toLowerCase()
                        .includes(
                          props.dropDownInputValue.toLocaleLowerCase()
                        ) && (
                        <div
                          onClick={() =>
                            props.onSelectDropDownOption(
                              props.dropDownFor,
                              option
                            )
                          }
                          className="hl_dropdown_option"
                          key={option.key}
                        >
                          <p
                            className="admin_filters_text"
                            style={{ color: "#1c1c1c" }}
                          >
                            {option.text}
                          </p>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p
                    className="admin_filters_text"
                    style={{ color: "#1c1c1c", paddingBottom: 10 }}
                  >
                    No Results..
                  </p>
                )}
              </div>
            ) : (
              <div className="hl_dropdown_options_cls">
                {props.dropdownOptions.map((option,i) => {
                  return (
                    <div
                      onClick={() =>
                        props.onSelectDropDownOption(props.dropDownFor, option)
                      }
                      className="hl_dropdown_option"
                      key={option.key !== undefined ? option.key : i}
                    >
                      <p
                        className="admin_filters_text"
                        style={{ color: "#1c1c1c" }}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            )
          ) : null}
        </div>
      </div>
      {props.updatedFeild === props.dropDownFor ? (
        <div style={{ marginTop: "0.5rem" }}>
          <p
            className={
              props.updateStatus.status === "SUCCESS"
                ? "hl_success_txt_cls"
                : "hl_err_txt_cls"
            }
          >
            {props.updateStatus.msg}
          </p>
        </div>
      ) : null}
      {props.isUpdated ? (
        <div style={{ marginTop: "0.25rem" }}>
          <p className="hl_err_txt_cls">**Save pending, Click update to Save</p>
        </div>
      ) : null}
    </div>
  );
}

export default HotelListingCustomDropDown;
